import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Landing from './Common/Landing';

function App() {
  return (
    <>
      <div className='app container'>
        <Switch>
          <Route>
            <Landing />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default App;