import React from 'react';
import Linkedin from '../images/linkedin.ico'
import Github from '../images/github-mark-white.png'

export default function Footer() {
    return (
        <>
            <footer>
                <a href='https://www.linkedin.com/in/traviscouncil/' target='_blank' rel="noreferrer" className=''><img className='icon' src={Linkedin} alt='Linkedin Icon' /></a>
                <a href='https://github.com/tcouncil/' target='_blank' rel="noreferrer" className='' ><img className='icon' src={Github} alt='Github Icon' /></a>
                {/*<a href="mailto:travis@councilstudios.com" className='button'>Email</a>*/}
            </footer>
        </>
    )
}